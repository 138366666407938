import Vue from "vue";
import store from "@/store";
import { getData } from "@/services/axios";
import { storeToRefs } from "pinia";
import { useSchoolPlanStore } from "@/stores/schoolPlan";

const ID_TOKEN_KEY = "id_token";
const ACCESS_TOKEN_KEY = "access_token";
const EXPIRES_AT_KEY = "expires_at";
const COGNITO_ID_KEY = "sub";

export const logout = async () => {
  [ACCESS_TOKEN_KEY, ID_TOKEN_KEY, EXPIRES_AT_KEY, COGNITO_ID_KEY].forEach(
    (key) => {
      window.localStorage.removeItem(key);
    }
  );
  await Vue.prototype.$cognito.logout();
};

export const showMessageBar = (message: string, timeout: number) => {
  store.commit("setStatus", true);
  store.commit("setStatusMessage", message);
  setTimeout(() => {
    store.commit("setStatus", false);
    store.commit("setStatusMessage", "");
  }, timeout);
};

export const getStudents = () => {
  const schoolPlanStore = useSchoolPlanStore();
  const { schoolPlan, isGovernmentSchool, isPlanStandard } =
    storeToRefs(schoolPlanStore);

  return getData("guardian_student", {
    with: ["student.school.school_plan", "student.school.government"],
  })
    .then((response: any) => {
      if (response.items) {
        const children = response.items.map(
          (
            v: {
              student: {
                id: any;
                name: any;
                ruby: any;
                icon: any;
                school: { name: any; plan: any };
                nickname: any;
              };
            },
            i: number
          ) => {
            return {
              id: v.student.id,
              name: v.student.name,
              ruby: v.student.ruby,
              icon: v.student.icon
                ? v.student.icon
                : "/img/icons/user0" + ((i + 1) % 5) + ".png",
              school_name: v.student.school ? v.student.school.name : "",
              school_plan: v.student.school ? v.student.school.plan : "",
              nickname: v.student.nickname,
            };
          }
        );
        store.dispatch("setChildren", children);
        schoolPlan.value = response.school_plan;
        isGovernmentSchool.value = response.is_government_school;

        isPlanStandard.value = children.some(
          (v: any) => v.school_plan === "standard"
        );
        if (isPlanStandard.value) {
          store.dispatch("setSchoolPlan", "standard");
        }
      }
    })
    .catch(function (error: any) {
      console.log(error);
      throw error;
    });
};

export const getGuardian = () => {
  return getData("guardians", { with: "user" })
    .then((response: any) => {
      if (response.items) {
        const me = response.items.find(
          (v: { user: { email: string } }) =>
            v.user.email.toLowerCase() ===
            Vue.prototype.$cognito.getUsername().toLowerCase()
        );
        store.dispatch("setGuardian", me);
      }
    })
    .catch(function (error: any) {
      console.log(error);
    });
};

export const getStudentWithGuardian = () => {
  return getData("students", { with: ["guardian.user", "user.school"] })
    .then((response: any) => {
      if (response.items) {
        store.dispatch("setStudentWithGuardian", response.items);
      }
    })
    .catch(function (error: any) {
      console.log(error);
    });
};

export const getMessageStatuses = (
  query: any,
  page?: number,
  perPage?: number
) => {
  // @ts-ignore
  const selectedChildren: any =
    store.state.children[store.state.selectedChildrenIndex];
  const student_id = selectedChildren ? selectedChildren.id : null;
  return getData("message_status", {
    with: "message",
    ...query,
    page,
    perPage,
    student_id,
  })
    .then((response: any) => {
      const messages = response.items.map((v: any) => {
        v.m_cdate = Vue.prototype
          .$moment(v.m_cdate)
          .format("YYYY年M月D日(ddd)");
        // @ts-ignore
        v.child = store.state.children
          ? store.state.children.find((v2: { id: string }) => v2.id === v.s_id)
          : {};
        return v;
      });

      // ページング前の総件数を取得
      const messagesCount = response.count;

      store.dispatch("setMessages", messages);
      store.dispatch("setMessagesCount", messagesCount);
    })
    .catch(function (error: any) {
      console.log(error);
    });
};

export const getSchedulesForCalendar = (current_date?: number) => {
  const student: any = store.state.children[store.state.selectedChildrenIndex];
  const student_id = student ? student.id : "";
  return getData(
    "schedules" +
      (student_id ? "?student_id=" + student_id + "&" : "?") +
      "current_date=" +
      current_date
  )
    .then((response: any) => {
      const schedules = response.items.map(
        (v: {
          is_all_day: any;
          date: string;
          from: {
            substring: {
              (arg0: number, arg1: number): void;
              (arg0: number, arg1: number): void;
            };
          };
          to: {
            substring: {
              (arg0: number, arg1: number): void;
              (arg0: number, arg1: number): void;
            };
          };
          child: {} | undefined;
          student_id: string;
        }) => {
          v.child = store.state.children
            ? store.state.children.find(
                (v2: { id: string }) => v2.id === v.student_id
              )
            : {};
          return v;
        }
      );
      store.dispatch("setSchedulesForCalendar", schedules);
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getEventsForCalendar = (currentDate?: number) => {
  return getData("events", {
    currentDate,
  })
    .then((response: any) => {
      store.dispatch("setEventsForCalendar", response.items);
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getAttendancesForCalendar = (currentDate?: number) => {
  const selectedChildren: any =
    store.state.children[store.state.selectedChildrenIndex];
  const student_id = selectedChildren ? selectedChildren.id : null;
  return getData("attendances", {
    student_id,
    currentDate,
  })
    .then((response: any) => {
      const today = Vue.prototype.$moment().startOf("day");
      const attendances = response.items.map(
        (v: {
          date: any;
          dateFormat: any;
          deletable: boolean;
          student_id: string;
        }) => {
          const date = Vue.prototype.$moment(v.date);
          v.dateFormat = date.format("YYYY年M月D日(ddd)");
          v.deletable = date >= today;
          // @ts-ignore
          v.child = store.state.children
            ? store.state.children.find(
                (v2: { id: string }) => v2.id === v.student_id
              )
            : {};
          return v;
        }
      );
      store.dispatch("setAttendancesForCalendar", attendances);
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getSchedulesAndEvents = (
  page?: number,
  perPage?: number,
  limit?: number
) => {
  // @ts-ignore
  const selectedChildren: any =
    store.state.children[store.state.selectedChildrenIndex];
  const student_id = selectedChildren ? selectedChildren.id : null;
  return getData("misc/schedules_and_events", {
    page,
    perPage,
    student_id,
    limit,
  })
    .then((response: any) => {
      const schedules = response.items.map(
        (v: {
          is_all_day: any;
          date: string;
          from: {
            substring: {
              (arg0: number, arg1: number): void;
              (arg0: number, arg1: number): void;
            };
          };
          to: {
            substring: {
              (arg0: number, arg1: number): void;
              (arg0: number, arg1: number): void;
            };
          };
          child: {} | undefined;
          student_id: string;
        }) => {
          if (v.is_all_day) {
            v.date =
              Vue.prototype.$moment(v.from).format("YYYY年M月D日(ddd)") +
              (v.from.substring(0, 10) !== v.to.substring(0, 10)
                ? " - " +
                  Vue.prototype.$moment(v.to).format("YYYY年M月D日(ddd)")
                : "");
          } else {
            v.date =
              Vue.prototype.$moment(v.from).format("YYYY年M月D日(ddd) H:mm") +
              (v.from.substring(0, 10) === v.to.substring(0, 10)
                ? " - " + Vue.prototype.$moment(v.to).format("H:mm")
                : " - " +
                  Vue.prototype.$moment(v.to).format("YYYY年M月D日 H:mm"));
          }
          // @ts-ignore
          v.child = store.state.children
            ? store.state.children.find(
                (v2: { id: string }) => v2.id === v.student_id
              )
            : {};
          return v;
        }
      );

      // ページング前の総件数を取得
      const schedulesCount = response.count;

      store.dispatch("setSchedulesAndEvents", schedules);
      store.dispatch("setSchedulesAndEventsCount", schedulesCount);
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const getAttendances = (page?: number, perPage?: number) => {
  // @ts-ignore
  const selectedChildren: any =
    store.state.children[store.state.selectedChildrenIndex];
  const student_id = selectedChildren ? selectedChildren.id : null;
  return getData("attendances", {
    page,
    perPage,
    student_id,
  })
    .then((response: any) => {
      const today = Vue.prototype.$moment().startOf("day");
      const attendances = response.items.map(
        (v: {
          date: any;
          dateFormat: any;
          deletable: boolean;
          student_id: string;
        }) => {
          //console.log(this.children);
          const date = Vue.prototype.$moment(v.date);
          v.dateFormat = date.format("YYYY年M月D日(ddd)");
          v.deletable = date >= today;
          // @ts-ignore
          v.child = store.state.children
            ? store.state.children.find(
                (v2: { id: string }) => v2.id === v.student_id
              )
            : {};
          return v;
        }
      );

      // ページング前の総件数を取得
      const attendancesCount = response.count;

      attendances.sort(
        (
          a: {
            created_at: any;
            date: any;
            dateFormat: any;
            deletable: boolean;
            student_id: string;
          },
          b: {
            created_at: any;
            date: any;
            dateFormat: any;
            deletable: boolean;
            student_id: string;
          }
        ) => {
          if (a.date > b.date) {
            return -1;
          }
          if (a.date < b.date) {
            return 1;
          }
          if (a.created_at > b.created_at) {
            return -1;
          }
          if (a.created_at < b.created_at) {
            return 1;
          }
          if (a.student_id > b.student_id) {
            return -1;
          }
          if (a.student_id < b.student_id) {
            return 1;
          }
          return 0;
        }
      );
      store.dispatch("setAttendances", attendances);
      store.dispatch("setAttendancesCount", attendancesCount);
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const itemsForCalendar = () => {
  const events = store.state.eventsForCalendar.map((v: any) => ({
    id: v.id,
    student_id: v.s_id,
    calendarId: 0,
    title: v.title + ":" + (v.nickname ? v.nickname : v.s_name),
    category: v.is_all_day ? "allday" : "time",
    isAllDay: false,
    start: v.is_all_day
      ? Vue.prototype.$moment(v.from).startOf("days").toISOString(true)
      : Vue.prototype.$moment(v.from).toISOString(true),
    end: v.is_all_day
      ? Vue.prototype.$moment(v.to).endOf("days").toISOString(true)
      : Vue.prototype.$moment(v.to).toISOString(true),
    isReadOnly: true,
  }));
  const schedules = store.state.schedulesForCalendar.map((v: any) => ({
    id: v.id,
    student_id: v.student_id,
    calendarId: 1,
    title:
      v.title +
      (v.child
        ? ":" + (v.child.nickname ? v.child.nickname : v.child.name)
        : ""),
    category: v.is_all_day ? "allday" : "time",
    isAllDay: v.is_all_day,
    start: v.is_all_day
      ? Vue.prototype.$moment(v.from).startOf("days").toISOString(true)
      : Vue.prototype.$moment(v.from).toISOString(true),
    end: v.is_all_day
      ? Vue.prototype.$moment(v.to).endOf("days").toISOString(true)
      : Vue.prototype.$moment(v.to).toISOString(true),
    isReadOnly: true,
  }));
  const attendances = store.state.attendancesForCalendar.map((v: any) => ({
    student_id: v.student_id,
    id: v.id,
    calendarId: 2,
    title:
      v.type +
      ":" +
      (v.child ? v.child.name : "") +
      (v.guardian_memo ? "＜" + v.guardian_memo + "＞" : ""),
    isAllDay: true,
    category: v.is_all_day ? "allday" : "time",
    start: Vue.prototype.$moment(v.date).toISOString(true),
    end: v.to_date
      ? Vue.prototype.$moment(v.to_date).toISOString(true)
      : Vue.prototype.$moment(v.date).toISOString(true),
    isReadOnly: true,
  }));
  return [...schedules, ...attendances, ...events];
};

export const localeList = {
  "ja-JP": "日本語",
  "en-US": "English",
  "es-US": "Español",
  "zh-CN": "中国語（簡体字）",
};

export const receiveMethodList = {
  both: "電話とメール",
  email: "メール",
  phone: "電話",
};

export const calendarOptions = {
  view: "month",
  month: {
    daynames: ["日", "月", "火", "水", "木", "金", "土"],
    isAlways6Week: true,
    startDayOfWeek: 1,
  },
  calendars: [
    {
      id: 0,
      name: "年間予定",
      color: "#ffffff",
      bgColor: "#FFA000",
      dragBgColor: "#FFA000",
      borderColor: "#FFA000",
    },
    {
      id: 1,
      name: "スケジュール",
      color: "#ffffff",
      bgColor: "#00a9ff",
      dragBgColor: "#00a9ff",
      borderColor: "#00a9ff",
    },
    {
      id: 2,
      name: "欠席連絡",
      color: "#ffffff",
      bgColor: "#9e5fff",
      dragBgColor: "#9e5fff",
      borderColor: "#9e5fff",
    },
  ],
  useDetailPopup: true,
  disableClick: true,
  disableDblClick: true,
  theme: {
    // month header 'dayname'
    "month.dayname.height": "32px",
    "month.dayname.borderLeft": "none",
    "month.dayname.paddingLeft": "8px",
    "month.dayname.paddingRight": "0",
    "month.dayname.fontSize": "13px",
    "month.dayname.backgroundColor": "inherit",
    "month.dayname.fontWeight": "normal",
    "month.dayname.textAlign": "left",
    "month.day.fontSize": "14px",

    // month schedule style
    "month.schedule.borderRadius": "3px",
    "month.schedule.height": "20px",
    "month.schedule.marginTop": "2px",
    "month.schedule.marginLeft": "10px",
    "month.schedule.marginRight": "10px",
  },
};
