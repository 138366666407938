import { getActivePinia } from "pinia";
import { getInstance } from "../cognito";
import store from "@/store/index";
import {
  getMessageStatuses,
  getStudents,
  getGuardian,
  getSchedulesAndEvents,
  logout,
} from "@/lib/util";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = async () => {
    // If the user is authenticated, continue with the route
    if (await authService.isAuthenticated()) {
      if (!store.state.guardian.id) {
        // piniaがactiveではない場合はTOP画面に遷移
        if (!getActivePinia()) {
          window.location.href = "/";
        }
        store.dispatch("setLoading", true);
        // todo 初期データの取得処理を外部に抽出する
        getStudents()
          .then(async () => {
            if (store.state.children.length > 0) {
              getGuardian();
              getMessageStatuses();
              getSchedulesAndEvents();
            } else {
              alert(
                "あなたのお子様がCOCOOをお使いの学校から卒業や転校をされたか、学校がCOCOOの契約を終了した可能性があります。ご活用ありがとうございました。"
              );
              await logout();
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            store.dispatch("setLoading", false);
          });
      }
      return next();
    }

    authService.loginWithRedirect(to.fullPath);
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

export const signupKeyGuard = (to, from, next) => {
  if (!store.state.signupKey) {
    return next("/");
  } else {
    return next();
  }
};
