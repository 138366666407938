import Vue from "vue";
import axios from "axios";
import { logout } from "@/lib/util";
import * as Sentry from "@sentry/vue";

export const axiosBase = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

export const getData = (target: any, payload?: any) => {
  const request = (id_token: string, access_token: string) => {
    const p = (t: string) => {
      return new Promise((resolve, reject) => {
        Vue.prototype.$http
          .get(t, {
            params: payload,
            headers: {
              Authorization: `Bearer ${id_token}`,
              "Access-Token": access_token,
            },
          })
          .then((response: { data: unknown }) => {
            resolve(response.data);
          })
          .catch(async (error: any) => {
            console.log(error);
            Sentry.captureException(error);
            if (error.response && error.response.status === 401) {
              await logout();
            }
            reject(error);
          });
      });
    };
    if (typeof target === "string") {
      return p(target);
    } else if (Array.isArray(target)) {
      return Promise.all(target.map((t) => p(t)));
    } else {
      return new Promise((resolve, reject) => {
        reject(null);
      });
    }
  };
  const id_token = Vue.prototype.$cognito.getToken();
  const access_token = Vue.prototype.$cognito.getAccessToken();
  return request(id_token, access_token);
};

export const createData = (target: any, payload: any, extraHeader = null) => {
  const id_token = Vue.prototype.$cognito.getToken();
  const access_token = Vue.prototype.$cognito.getAccessToken();
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${id_token}`,
      "Access-Token": access_token,
    },
  };
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .post(target, payload, Object.assign(requestHeader, extraHeader))
      .then((response: { data: unknown }) => {
        resolve(response.data);
      })
      .catch((error: { response: { status: number } }) => {
        console.log(error);
        Sentry.captureException(error);
        if (error.response && error.response.status === 401) {
          logout();
        }
        reject(error);
      });
  });
};

export const updateData = (target: any, payload: any, extraHeader = null) => {
  const id_token = Vue.prototype.$cognito.getToken();
  const access_token = Vue.prototype.$cognito.getAccessToken();
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${id_token}`,
      "Access-Token": access_token,
    },
  };
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .put(target, payload, Object.assign(requestHeader, extraHeader))
      .then((response: { data: unknown }) => {
        resolve(response.data);
      })
      .catch((error: { response: { status: number } }) => {
        console.log(error);
        Sentry.captureException(error);
        if (error.response && error.response.status === 401) {
          logout();
        }

        reject(error);
      });
  });
};

export const updateDataForChild = (
  target: any,
  payload: any,
  extraHeader = null
) => {
  const id_token = Vue.prototype.$cognito.getToken();
  const access_token = Vue.prototype.$cognito.getAccessToken();
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${id_token}`,
      "Access-Token": access_token,
      "X-HTTP-Method-Override": "PUT",
    },
  };
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .post(target, payload, Object.assign(requestHeader, extraHeader))
      .then((response: { data: unknown }) => {
        resolve(response.data);
      })
      .catch((error: { response: { status: number } }) => {
        console.log(error);
        Sentry.captureException(error);
        if (error.response && error.response.status === 401) {
          logout();
        }

        reject(error);
      });
  });
};

export const deleteData = (target: any) => {
  const id_token = Vue.prototype.$cognito.getToken();
  const access_token = Vue.prototype.$cognito.getAccessToken();
  const requestHeader = {
    headers: {
      Authorization: `Bearer ${id_token}`,
      "Access-Token": access_token,
    },
  };
  return new Promise((resolve, reject) => {
    Vue.prototype.$http
      .delete(target, requestHeader)
      .then((response: { data: unknown }) => {
        resolve(response.data);
      })
      .catch((error: { response: { status: number } }) => {
        console.log(error);
        Sentry.captureException(error);
        if (error.response && error.response.status === 401) {
          logout();
        }

        reject(error);
      });
  });
};
