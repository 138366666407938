import Vue from "vue";
import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  RespondToAuthChallengeCommand,
  GlobalSignOutCommand,
  AuthFlowType,
} from "@aws-sdk/client-cognito-identity-provider";
import moment from "moment";
import axios from "axios";

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

export const useCognito = (options) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        userPoolId: null,
        clientId: null,
        session: null,
      };
    },
    async created() {
      this.userPoolId = options.userPoolId;
      this.clientId = options.clientId;
      this.client = new CognitoIdentityProviderClient({
        region: options.region,
      });
      this.loading = false;
    },
    methods: {
      async challenge(username) {
        const input = {
          AuthFlow: AuthFlowType.USER_AUTH,
          AuthParameters: {
            PREFERRED_CHALLENGE: "EMAIL_OTP",
            USERNAME: username,
          },
          ClientId: this.clientId,
        };
        const command = new InitiateAuthCommand(input);
        const response = await this.client.send(command);
        this.session = response.Session;
      },
      async challengeSms(username) {
        const input = {
          AuthFlow: AuthFlowType.USER_AUTH,
          AuthParameters: {
            PREFERRED_CHALLENGE: "SMS_OTP",
            USERNAME: username,
          },
          ClientId: this.clientId,
        };
        const command = new InitiateAuthCommand(input);
        const response = await this.client.send(command);
        this.session = response.Session;
      },
      async logout() {
        const session = JSON.parse(localStorage.getItem("cocoo-session"));
        if (session && session.AccessToken) {
          try {
            const command = new GlobalSignOutCommand({
              AccessToken: session.AccessToken,
            });
            await this.client.send(command);
          } catch (e) {
            console.log(e);
          } finally {
            localStorage.removeItem("cocoo-session");
            localStorage.removeItem("cocoo-username");
            localStorage.removeItem("cocoo-path");
          }
        }
        window.location.href = "/";
      },
      async confirmation(username, confirmationCode, type = "email") {
        this.loading = false;
        const input =
          type === "sms"
            ? {
                ChallengeName: "SMS_OTP",
                Session: this.session,
                ChallengeResponses: {
                  USERNAME: username,
                  SMS_OTP_CODE: confirmationCode,
                },
                ClientId: this.clientId,
              }
            : {
                ChallengeName: "EMAIL_OTP",
                Session: this.session,
                ChallengeResponses: {
                  USERNAME: username,
                  EMAIL_OTP_CODE: confirmationCode,
                },
                ClientId: this.clientId,
              };
        const command = new RespondToAuthChallengeCommand(input);
        const response = await this.client.send(command);
        const expires = response.AuthenticationResult.ExpiresIn;
        const expireDate = moment().add(expires, "s").toDate();
        localStorage.setItem(
          "cocoo-session",
          JSON.stringify({
            ...response.AuthenticationResult,
            expireDate,
          })
        );
        localStorage.setItem("cocoo-username", username);
        this.loading = false;
        const path = localStorage.getItem("cocoo-path");
        localStorage.removeItem("cocoo-path");
        if (path && path.startsWith("/")) {
          window.location.href = path;
        } else {
          window.location.href = "/";
        }
      },
      async isAuthenticated() {
        const session = JSON.parse(localStorage.getItem("cocoo-session"));
        if (session) {
          if (new Date(session.expireDate) < new Date()) {
            const clientId = process.env.VUE_APP_COGNITO_CLIENT_ID;
            const url = `https://cognito-idp.ap-northeast-1.amazonaws.com/`;
            const session = JSON.parse(localStorage.getItem("cocoo-session"));
            if (!session.RefreshToken) {
              localStorage.removeItem("cocoo-session");
              localStorage.removeItem("cocoo-username");
              localStorage.removeItem("cocoo-path");
              return false;
            }
            const data = {
              AuthFlow: "REFRESH_TOKEN_AUTH",
              ClientId: clientId,
              AuthParameters: {
                REFRESH_TOKEN: session.RefreshToken,
              },
            };

            try {
              const response = await axios.post(url, data, {
                headers: {
                  "Content-Type": "application/x-amz-json-1.1",
                  "X-Amz-Target":
                    "AWSCognitoIdentityProviderService.InitiateAuth",
                },
              });
              const expires = response.data.AuthenticationResult.ExpiresIn;
              const expireDate = moment().add(expires, "s").toDate();
              const updatedSession = {
                ...session,
                AccessToken: response.data.AuthenticationResult.AccessToken,
                IdToken: response.data.AuthenticationResult.IdToken,
                expireDate,
              };
              localStorage.setItem(
                "cocoo-session",
                JSON.stringify(updatedSession)
              );
            } catch (error) {
              console.error(
                "Error refreshing token:",
                error.response ? error.response.data : error.message
              );
              localStorage.removeItem("cocoo-session");
              localStorage.removeItem("cocoo-username");
              localStorage.removeItem("cocoo-path");
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      },
      async loginWithRedirect(path) {
        if (await this.isAuthenticated()) {
          if (path && path.startsWith("/")) {
            window.location.href = path;
          } else {
            window.location.href = "/";
          }
        } else {
          localStorage.setItem("cocoo-path", path);
          window.location.href = "/";
        }
      },
      getToken() {
        const session = JSON.parse(localStorage.getItem("cocoo-session"));
        if (session) {
          return session.IdToken;
        }
        return null;
      },
      getAccessToken() {
        const session = JSON.parse(localStorage.getItem("cocoo-session"));
        if (session) {
          return session.AccessToken;
        }
        return null;
      },
      getUsername() {
        const username = localStorage.getItem("cocoo-username");
        return username || "";
      },
    },
  });
  return instance;
};

export const Cognito = {
  install(Vue, options) {
    Vue.prototype.$cognito = useCognito(options);
  },
};
